/**
 * WARNING: this file is generated. Don't change it manually.
 */
export default class Images {
    static readonly appVersion = require("../../../src/assets/images/App Version.png");
    static readonly appVersion2 = require("../../../src/assets/images/App Version2.png");
    static readonly avatarEmpty = require("../../../src/assets/images/Avatar Empty.png");
    static readonly bell = require("../../../src/assets/images/Bell.png");
    static readonly bgDiscount = require("../../../src/assets/images/Bg Discount.png");
    static readonly blogsManage = require("../../../src/assets/images/Blogs Manage.png");
    static readonly blogsManage2 = require("../../../src/assets/images/Blogs Manage2.png");
    static readonly bulletinBoard = require("../../../src/assets/images/Bulletin Board.png");
    static readonly bulletinBoard2 = require("../../../src/assets/images/Bulletin Board2.png");
    static readonly cameraBlue = require("../../../src/assets/images/Camera Blue.png");
    static readonly dashboard = require("../../../src/assets/images/Dashboard.png");
    static readonly dashboard2 = require("../../../src/assets/images/Dashboard2.png");
    static readonly download = require("../../../src/assets/images/Download.png");
    static readonly empty = require("../../../src/assets/images/Empty.png");
    static readonly filter = require("../../../src/assets/images/Filter.png");
    static readonly forward = require("../../../src/assets/images/Forward.png");
    static readonly helpCenter = require("../../../src/assets/images/Help Center.png");
    static readonly helpCenter2 = require("../../../src/assets/images/Help Center2.png");
    static readonly home = require("../../../src/assets/images/Home.png");
    static readonly information = require("../../../src/assets/images/Information.png");
    static readonly krFlags = require("../../../src/assets/images/KR Flags.png");
    static readonly krw = require("../../../src/assets/images/KRW.png");
    static readonly krwBlue = require("../../../src/assets/images/KRWBlue.png");
    static readonly mapPoint = require("../../../src/assets/images/Map Point.png");
    static readonly messageIconBlue = require("../../../src/assets/images/MessageIconBlue.png");
    static readonly penInactive = require("../../../src/assets/images/Pen-inactive.png");
    static readonly pen = require("../../../src/assets/images/Pen.png");
    static readonly phoneIconBlue = require("../../../src/assets/images/PhoneIconBlue.png");
    static readonly pinMap = require("../../../src/assets/images/Pin Map.png");
    static readonly pointHistory = require("../../../src/assets/images/Point History.png");
    static readonly pointHistory2 = require("../../../src/assets/images/Point History2.png");
    static readonly radioActive = require("../../../src/assets/images/Radio-active.png");
    static readonly radioInactive = require("../../../src/assets/images/Radio-inactive.png");
    static readonly reports = require("../../../src/assets/images/Reports.png");
    static readonly reports2 = require("../../../src/assets/images/Reports2.png");
    static readonly seoPage = require("../../../src/assets/images/SEO Page.png");
    static readonly seoPage2 = require("../../../src/assets/images/SEO Page2.png");
    static readonly setting = require("../../../src/assets/images/Setting.png");
    static readonly setting2 = require("../../../src/assets/images/Setting2.png");
    static readonly share = require("../../../src/assets/images/Share.png");
    static readonly shopping = require("../../../src/assets/images/Shopping.png");
    static readonly shopping2 = require("../../../src/assets/images/Shopping2.png");
    static readonly smallChart1 = require("../../../src/assets/images/Small Chart-1.png");
    static readonly smallChart = require("../../../src/assets/images/Small Chart.png");
    static readonly statistics1 = require("../../../src/assets/images/Statistics-1.png");
    static readonly statistics = require("../../../src/assets/images/Statistics.png");
    static readonly store = require("../../../src/assets/images/Store.png");
    static readonly store2 = require("../../../src/assets/images/Store2.png");
    static readonly subscription = require("../../../src/assets/images/Subscription.png");
    static readonly subscription2 = require("../../../src/assets/images/Subscription2.png");
    static readonly trash = require("../../../src/assets/images/Trash.png");
    static readonly trash3 = require("../../../src/assets/images/Trash3.png");
    static readonly trashred = require("../../../src/assets/images/Trashred.png");
    static readonly undo = require("../../../src/assets/images/Undo.png");
    static readonly upload = require("../../../src/assets/images/Upload.png");
    static readonly uploadCloudIcon = require("../../../src/assets/images/UploadCloudIcon.png");
    static readonly uploadExcel = require("../../../src/assets/images/UploadExcel.png");
    static readonly userManage = require("../../../src/assets/images/User Manage.png");
    static readonly userManage2 = require("../../../src/assets/images/User Manage2.png");
    static readonly addFile = require("../../../src/assets/images/add-file.png");
    static readonly adult = require("../../../src/assets/images/adult.png");
    static readonly android = require("../../../src/assets/images/android.png");
    static readonly android2 = require("../../../src/assets/images/android2.png");
    static readonly androidIcon = require("../../../src/assets/images/androidIcon.png");
    static readonly appleIcon = require("../../../src/assets/images/appleIcon.png");
    static readonly archiveTick = require("../../../src/assets/images/archive-tick.png");
    static readonly arrowDown = require("../../../src/assets/images/arrowDown.png");
    static readonly arrowDown2 = require("../../../src/assets/images/arrowDown2.png");
    static readonly arrowLeft = require("../../../src/assets/images/arrowLeft.png");
    static readonly arrowUp = require("../../../src/assets/images/arrowUp.png");
    static readonly arrowUp2 = require("../../../src/assets/images/arrowUp2.png");
    static readonly bank1 = require("../../../src/assets/images/bank1.png");
    static readonly bank10 = require("../../../src/assets/images/bank10.png");
    static readonly bank2 = require("../../../src/assets/images/bank2.png");
    static readonly bank3 = require("../../../src/assets/images/bank3.png");
    static readonly bank4 = require("../../../src/assets/images/bank4.png");
    static readonly bank5 = require("../../../src/assets/images/bank5.png");
    static readonly bank6 = require("../../../src/assets/images/bank6.png");
    static readonly bank7 = require("../../../src/assets/images/bank7.png");
    static readonly bank8 = require("../../../src/assets/images/bank8.png");
    static readonly bank9 = require("../../../src/assets/images/bank9.png");
    static readonly bg = require("../../../src/assets/images/bg.png");
    static readonly bgLogin1 = require("../../../src/assets/images/bgLogin1.jpg");
    static readonly bgLogin2 = require("../../../src/assets/images/bgLogin2.jpg");
    static readonly bgLogin3 = require("../../../src/assets/images/bgLogin3.jpg");
    static readonly bgLogin4 = require("../../../src/assets/images/bgLogin4.jpg");
    static readonly bgLogin5 = require("../../../src/assets/images/bgLogin5.jpg");
    static readonly board = require("../../../src/assets/images/board.png");
    static readonly board2 = require("../../../src/assets/images/board2.png");
    static readonly calendar = require("../../../src/assets/images/calendar.png");
    static readonly cancel = require("../../../src/assets/images/cancel.png");
    static readonly cancelCircleActive = require("../../../src/assets/images/cancelCircleActive.png");
    static readonly category2 = require("../../../src/assets/images/category2.png");
    static readonly checkedCircleActive = require("../../../src/assets/images/checkedCircleActive.png");
    static readonly checkedCircleInactive = require("../../../src/assets/images/checkedCircleInactive.png");
    static readonly chevronDownTiny = require("../../../src/assets/images/chevron-down-tiny.png");
    static readonly chevronRightTiny = require("../../../src/assets/images/chevron-right-tiny.png");
    static readonly chevronUpTiny = require("../../../src/assets/images/chevron-up-tiny.png");
    static readonly clock = require("../../../src/assets/images/clock.png");
    static readonly clockBlue = require("../../../src/assets/images/clockBlue.png");
    static readonly closeCircle = require("../../../src/assets/images/closeCircle.png");
    static readonly collapse = require("../../../src/assets/images/collapse.png");
    static readonly collapse2 = require("../../../src/assets/images/collapse2.png");
    static readonly copy = require("../../../src/assets/images/copy.png");
    static readonly cross = require("../../../src/assets/images/cross.png");
    static readonly dataEmpty = require("../../../src/assets/images/dataEmpty.png");
    static readonly discount1 = require("../../../src/assets/images/discount 1.png");
    static readonly documentCopy = require("../../../src/assets/images/document-copy.png");
    static readonly dot = require("../../../src/assets/images/dot.png");
    static readonly edit = require("../../../src/assets/images/edit.png");
    static readonly edit2 = require("../../../src/assets/images/edit2.png");
    static readonly edit2White = require("../../../src/assets/images/edit2White.png");
    static readonly editIcon2 = require("../../../src/assets/images/editIcon2.png");
    static readonly editPencil = require("../../../src/assets/images/editPencil.png");
    static readonly ellipsis = require("../../../src/assets/images/ellipsis.png");
    static readonly emailIconActive = require("../../../src/assets/images/email-icon-active.png");
    static readonly exportIcon = require("../../../src/assets/images/exportIcon.png");
    static readonly eyeCross = require("../../../src/assets/images/eye-cross.png");
    static readonly eye = require("../../../src/assets/images/eye.png");
    static readonly filled = require("../../../src/assets/images/filled.png");
    static readonly gridActive = require("../../../src/assets/images/grid-active.png");
    static readonly gridInactive = require("../../../src/assets/images/grid-inactive.png");
    static readonly hourglassIcon = require("../../../src/assets/images/hourglassIcon.png");
    static readonly icEnglish = require("../../../src/assets/images/ic-english.png");
    static readonly icImage = require("../../../src/assets/images/icImage.png");
    static readonly icon18 = require("../../../src/assets/images/icon18+.png");
    static readonly iconCategory = require("../../../src/assets/images/iconCategory.png");
    static readonly iconClock = require("../../../src/assets/images/iconClock.png");
    static readonly iconDirection = require("../../../src/assets/images/iconDirection.png");
    static readonly iconGps = require("../../../src/assets/images/iconGPS.png");
    static readonly iconHeart = require("../../../src/assets/images/iconHeart.png");
    static readonly iconPhone = require("../../../src/assets/images/iconPhone.png");
    static readonly iconPhone2 = require("../../../src/assets/images/iconPhone2.png");
    static readonly iconSquare = require("../../../src/assets/images/iconSquare.png");
    static readonly iconTag = require("../../../src/assets/images/iconTag.png");
    static readonly infoCircle = require("../../../src/assets/images/info-circle.png");
    static readonly infoIcon = require("../../../src/assets/images/infoIcon.png");
    static readonly iphone = require("../../../src/assets/images/iphone.png");
    static readonly iphone2 = require("../../../src/assets/images/iphone2.png");
    static readonly logo = require("../../../src/assets/images/logo.jpg");
    static readonly logoKormsg = require("../../../src/assets/images/logoKormsg.png");
    static readonly mapPin = require("../../../src/assets/images/map-pin.png");
    static readonly minusCircle = require("../../../src/assets/images/minusCircle.png");
    static readonly noPost = require("../../../src/assets/images/noPost.png");
    static readonly padlock = require("../../../src/assets/images/padlock.png");
    static readonly plus = require("../../../src/assets/images/plus.png");
    static readonly plus2 = require("../../../src/assets/images/plus2.png");
    static readonly plusCircle = require("../../../src/assets/images/plusCircle.png");
    static readonly reservationConfirmedActive = require("../../../src/assets/images/reservationConfirmedActive.png");
    static readonly reservationConfirmedInactive = require("../../../src/assets/images/reservationConfirmedInactive.png");
    static readonly search = require("../../../src/assets/images/search.png");
    static readonly setting3 = require("../../../src/assets/images/setting3.png");
    static readonly statistic1 = require("../../../src/assets/images/statistic1.png");
    static readonly statistic2 = require("../../../src/assets/images/statistic2.png");
    static readonly statistic3 = require("../../../src/assets/images/statistic3.png");
    static readonly statistic4 = require("../../../src/assets/images/statistic4.png");
    static readonly success = require("../../../src/assets/images/success.png");
    static readonly trash2 = require("../../../src/assets/images/trash2.png");
    static readonly twoDot = require("../../../src/assets/images/twoDot.png");
    static readonly upload2 = require("../../../src/assets/images/upload2.png");
    static readonly userDefault = require("../../../src/assets/images/user_default.png");
    static readonly web = require("../../../src/assets/images/web.png");
    static readonly web2 = require("../../../src/assets/images/web2.png");
    static readonly webMobile = require("../../../src/assets/images/webMobile.png");
    static readonly webPc = require("../../../src/assets/images/webPC.png");
    static readonly leaderBoardOff = require("../../../src/assets/images/leaderBoardOff.png");
    static readonly leaderBoardOn = require("../../../src/assets/images/leaderBoardOn.png");
}
