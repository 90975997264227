export const AUTH = "/auth";
export const LOGOUT = "";
export const USER = "/user";
export const SETTING_ADMIN = "/setting_admin";
export const BANNER = "/banner";
export const NAVBAR = "/navbar";
export const BOARD_LINK = "/link";
export const BLOG = "/blog";
export const EMPLOYEE = "/employee";
export const SHOP = "/shop";
export const SEO = "/seo_ssr";
export const HISTORY = "/history";
export const GROUP = "/group";
export const CONVERSATION = "/conversation";
export const RESERVATION = "/reservation";
export const REVIEW = "/review";
export const POST = "/post";
export const COURSE = "/course";
export const MENTOR = "/mentor";
export const MESSAGE = "/message";
export const TRANSACTION = "/transaction";
export const SITE = "/site";
export const GLOBAL = "/global";
export const REFRESH_TOKEN = "/user/refresh";
export const STORE = "/shop";
export const APP_VERSION = "/app_version";
export const THEMA = "/thema";
export const CATEGORY = "/category";
export const TAG = "/tag";
export const REPORT = "/report";
export const QUESTION = "/question";
export const ANSWER_QUESTION = "/answer_question";
export const SETTING = "/setting";
export const FAQ_CATEGORY = "/faq_category";
export const FAQ = "/faq";
export const POINT_PRODUCT = "/point_product";
export const POINT = "/point";
export const POINT_PRODUCT_HISTORY = "/point_product_history";
export const SITE_CATEGORY = "/site_category";
export const REGION = "/setting_province";
export const DISTRICT = "/setting_district";
export const STATION = "/setting_station";
export const STATION_LINE = "/setting_station_line";
export const SUBWAY = "/setting_station_subway";
export const POPUP = "/popup";
